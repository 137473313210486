@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
div#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: black;
}

div,
nav {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

@media (prefers-color-scheme: dark) {
  body, textarea{
    background-color: white !important;
  }

  thead, tbody, .dashboard-item {
    color: black;
  }

  .ios .body{
    background-color: white !important;
  }

  .md .body{
    background-color: white !important;
  }
}

@media (prefers-color-scheme: light) {
  body, textarea{
    background-color: white !important;
  }

  thead, tbody, .dashboard-item {
    color: black;
  }

  .ios .body{
    background-color: white !important;
  }

  .md .body{
    background-color: white !important;
  }
}
