.privacy-policy {
  font-size: 14px;
  color: #8798ad;
}

.privacy-policy ol {
  list-style-type: decimal;
}

.privacy-policy ol[type='a'] {
  list-style-type: lower-alpha;
}

.privacy-policy ol[type='a'] {
  padding-inline-start: 16px;
}

.privacy-policy ol > li {
  margin: 10px 0;
  padding-inline-start: 10px;
}

.privacy-policy p {
  margin: 10px 0;
}

.privacy-policy a {
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2rem;
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
  font-weight: 400;
  text-align: left;
  color: #34049c;
}

.privacy-policy ul {
  list-style-type: disc;
  padding-inline-start: 20px;
}
