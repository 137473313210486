.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  width: 12px;
  height: 12px;
  background-color: #FBFBFB;
  opacity: 1;
  border: 1px solid #FBFBFB;
  border-radius: 50%;
  content: '';
}

.slick-dots li.slick-active button:before {
  background-color: #998CFF; 
}

.slider-container {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}


.slick-dots-wrapper {
  margin-top: 20px; /* Adjust the space between the slider and dots */
  height: auto;
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.slick-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1;
}

.slick-track {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.slick-active {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.slick-slide {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.slick-initialized .slick-slide {
  display: flex;
}


.slick-dots {
  bottom: 0;
}

.slick-next {
  right: 0;
  z-index: 2;
}

.slick-next:before {
  content: '';
}


.slick-prev {
  left: 0;
  z-index: 2;
}

.slick-prev:before {
  content: '';
}
