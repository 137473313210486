.app {
  height: 100%;
}

html {
  margin-top: env(safe-area-inset-top);
}

.navBar {
  top: env(safe-area-inset-top) !important;
}

.bottom-nav,
.chat-input-wrapper {
  bottom: env(safe-area-inset-bottom) !important;
}

.floating-card {
  top: calc(env(safe-area-inset-top) + 56px) !important
}

.floating-card-2 {
  top: calc(env(safe-area-inset-top) + 115px) !important
}

.side-menu-logo {
  margin-top: env(safe-area-inset-top) !important;
}

.chat-content-wrapper {
  max-height: calc(100% - env(safe-area-inset-top));
}